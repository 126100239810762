import React from 'react';
import { fieldFactory as baseFieldFactory } from '@api-platform/admin';
import { BooleanField, DateField } from 'react-admin';
import QuestionMarkIcon from '@material-ui/icons/Help';

export default (field, options) => {

  if(field.range.includes("http://www.w3.org/2001/XMLSchema#boolean")) {
    return (
      <BooleanField key={field.name} source={field.name} {...field.inputProps}>
        <Nullable/>
      </BooleanField>
    )
  }

  if(field.id.includes("Survey/updatedAt")) {
    return (
      <DateField key={field.name} source={field.name} {...field.inputProps} showTime />
    )
  }

  return baseFieldFactory(field, options);
};

const Nullable = () => (<QuestionMarkIcon/>);
