import { inputFactory as baseInputFactory } from '@api-platform/admin';
import {
  RadioButtonGroupInput,
} from 'react-admin';
import React from 'react';

export default (field, options) => {
  const props = {...field.inputProps};

  if (field.id.includes("QuestionCriteria/responseType")) return questionCriteriaResponseType(field, props);
  if (field.id.includes("businessSegment")) return businessSegmentInput(field, props);
  if (field.id.includes("category")) return categoryInput(field, props);

  switch (field.range) {
    case 'http://www.w3.org/2001/XMLSchema#boolean':
      if(field.id.includes("QuestionCriteria/value")) {
        return useRadioForNullableBoolean(field, props, [
          { id: 'true', name: '+' },
          { id: 'false', name: '-' },
          { id: '', name: '0' },
        ]);
      }

      if(!field.required) return useRadioForNullableBoolean(field, props);
      break;
    default:
      return baseInputFactory(field, options);
  }

  return baseInputFactory(field, options);
};

const useRadioForNullableBoolean = (field, props, choices = [
  { id: 'true', name: 'Oui' },
  { id: 'false', name: 'Non' },
  { id: '', name: 'Je ne sais pas' },
]) => {
  const isTrue = (value) => 'true' === value;

  field.denormalizeData = value => {
    return value === null ? '' : `${value}`
  };

  field.normalizeData = value => {
    return "" === value ? null : isTrue(value);
  };

  return <RadioButtonGroupInput key={field.name} source={field.name} {...props} choices={choices} />
};

const questionCriteriaResponseType = (field, props) => {
  field.normalizeData = value => `${value}`;

  return <RadioButtonGroupInput key={field.name} source={field.name} {...props} choices={[
    { id: 'yes', name: 'Oui' },
    { id: 'no', name: 'Non' },
  ]} />
};

const businessSegmentInput = (field, props) => {
  props.label = 'resources.business_segment_variants.fields.businessSegment';
  field.inputProps = props;

  return baseInputFactory(field, {});
};

const categoryInput = (field, props) => {
  props.label = 'resources.questions.fields.category';
  field.inputProps = props;

  return baseInputFactory(field, {});
};
