import React from 'react';
import { Create, SimpleForm, ShowButton, EditButton } from 'react-admin';
import { List, Datagrid, TextField, Edit, Show, ReferenceField, NumberField } from 'react-admin';
import { TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';
import {fieldsToInputs, resourcesPropertiesToFields} from "../../helpers/fieldTransformer";
import {isSuperAdmin} from "../../helpers/permissions";
import CustomPagination from '../../components/Pagination';

export const SurveyList = (props) => {
    const {options: {api, resource }} = props;
    const fields = resourcesPropertiesToFields(api, resource, ['id', 'businessSegment', 'email', 'lock', 'updatedAt']);

    return (
        <List {...props} bulkActionButtons={false} exporter={null} pagination={<CustomPagination/>} perPage={50}>
            <Datagrid>
                {fields.map(field =>
                    field
                )}
                <NumberField source="acceptabilityScore"/>
                {isSuperAdmin(props.permissions) && <EditButton basePath="/surveys" />}
                <ShowButton />
            </Datagrid>
        </List>
    );
};
export const SurveyCreate = (props) => {
    const {options: {api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Create { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    field
                )}
            </SimpleForm>
        </Create>
    )
};

export const SurveyEdit = (props) => {
    const {options: {api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Edit { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    field
                )}
            </SimpleForm>
        </Edit>
    )
};

export const SurveyShow = (props) => (
    <Show {...props} title={"Questionnaire"}>
        <TabbedShowLayout>
            <Tab label="app.tab.summary">
                {isSuperAdmin(props) && <TextField label="Id" source="id" />}
                {resourcesPropertiesToFields(
                    props.options.api,
                    props.options.resource,
                    ['businessSegment', 'email', 'lock']
                ).map(field => field)}
                <NumberField source="acceptabilityScore"/>
            </Tab>
            <Tab label='app.resources.answers'>
                <ReferenceManyField target="survey" reference="answers" addLabel={false}>
                    <Datagrid>
                      {resourcesPropertiesToFields(
                        props.options.api,
                        props.options.api.resources.find(resource => resource.name === "answers"),
                        ['value']
                      ).map(field => field)}
                        <ReferenceField source="question" reference="questions">
                            <TextField source="title" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
