import React from 'react';
import { Create, SimpleForm, ShowButton, EditButton } from 'react-admin';
import { Show, SimpleShowLayout, List, Datagrid, Edit } from 'react-admin';
import {fieldsToInputs, resourcesPropertiesToFields} from "../../helpers/fieldTransformer";

const CategoryTitle = ({ record }) => {
    return <span>Categorie {record ? `"${record.name}"` : ''}</span>;
};

export const CategoryList = (props) => {

    const {options: {api, resource }} = props;
    const fields = resourcesPropertiesToFields(api, resource, ['name']);

    return (
        <List {...props} bulkActionButtons={false} exporter={null}>
            <Datagrid>
                {fields.map(field =>
                    field
                )}
                <EditButton basePath="/categories" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
export const CategoryCreate = (props) => {
    const {options: {api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Create { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    field
                )}
            </SimpleForm>
        </Create>
    )
};

export const CategoryEdit = (props) => {
    const {options: {api, resource }} = props;
    const fields = fieldsToInputs(api, resource);
 return (
     <Edit title={<CategoryTitle />} {...props}>
         <SimpleForm>
             {fields.map(field =>
                 field
             )}
         </SimpleForm>
     </Edit>
 );
};

export const CategoryShow = (props) => {

    const {options: {api, resource }} = props;
    const fields = resourcesPropertiesToFields(api, resource);

    return (
        <Show {...props} title={<CategoryTitle />}>
            <SimpleShowLayout>
                {fields.map(field =>
                    field
                )}
            </SimpleShowLayout>
        </Show>
    );
};
